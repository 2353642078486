.header {
  width: 100%;
  height: 55px;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 999;
  background-color: #1f232b;
  font-size: 22px;
  color: #fff;
  opacity: 0.96;
  &__burger {
    display: none;
  }
  &__img img {
    width: 132px;
  }
  &__connect {
    display: flex;
    align-items: center;
  }
  &__connect-box {
    margin-right: -75px;
  }
  &__nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__connect-box-item:nth-child(2) {
    padding-right: 15px;
  }
  &__connect-box-link {
    &:hover {
      color: darkturquoise;
    }
    span {
      top: -4px;
      position: relative;
    }
  }
  &__list {
    display: flex;
  }
  &__connect-form {
    padding: 0 12px;
    background-color: rgb(44 161 177);
    border-radius: 10px;
    height: 45px;
    display: flex;
    align-items: center;
  }
  &__connect-form:hover {
    background-color: rgb(38 88 175);
    color: darkturquoise;
  }
  &__connect-link {
    display: block;
    text-align: center;
    margin-right: 15px;
  }
  &__connect-link:hover {
    color: darkturquoise;
  }
  &__item {
    margin-right: 15px;
    &:hover {
      color: darkturquoise;
    }
  }
}

.menu {
  &__header {
    margin-bottom: 5px;
    margin-left: -106px;
  }
  &__item {
    margin-bottom: 5px;
    &:first-child {
      margin-top: 10px;
    }
  }
}
