.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: -ms-flexbox;
  padding: 36px 16px;
}

.form {
  max-width: 600px;
  width: 100%;
  height: 600px;
  margin: 0 auto;
  padding: 20px 30px;
  position: relative;
  border-radius: 10px;
  border: 1px solid gray;
  background-color: white;
  top: 50%;
  transform: translateY(-50%);
  &__title {
    color: rgb(66, 63, 97);
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
  }
  &__close {
    position: absolute;
    right: 15px;
    top: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      opacity: 0.7;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
  &__item {
    border-bottom: 2px solid #423f61;
  }
  &__input {
    position: relative;
    display: block;
    width: 100%;
    text-decoration: none;
    border: none;
    padding: 15px 0;
    &.area {
      height: 150px;
    }
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
  }
  &__submit {
    margin-top: 35px;
    padding: 15px 0;
    background-color: #00ace9;
    color: #fff;
    width: 100%;
    text-decoration: none;
    border: none;
    cursor: pointer;
    &:hover {
      background-color: #0099d0;
    }
  }
  &__p {
    color: red;
    margin: 0;
  }
}
