.menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 50px;
  left: 0;
  transform: translateX(-130%);
  transition: all 0.2s;
}

.menu.active {
  transform: translateX(0);
}

.blur {
  width: 100vw;
  height: 100vh;
  left: 30%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
}

.menu__content {
  width: 30%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s;
}

.menu__header {
  color: white;
}

.material-icons {
  color: white;
}

li a {
  min-width: 80px;
}

ul li {
  display: flex;
  align-items: center;
}
