.footer {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  z-index: 999;
  background-color: #1f232b;
  font-size: 22px;
  color: wheat;
  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__inner-items-link {
    &:hover {
      color: goldenrod;
    }
  }

  &__inner-items-list:first-child {
    font-size: 22px;
    margin-bottom: 19px;
  }

  &__inner-items-item {
    font-size: 17px;
    margin-bottom: 5px;
    &.first {
      font-size: 22px;
      font-weight: 500;
    }
  }

  &__inner-socials-list {
    font-size: 22px;
  }

  &__inner-socials-item {
    font-size: 17px;
    margin-bottom: 5px;
    cursor: pointer;
    &.first {
      font-size: 22px;
      font-weight: 500;
    }
  }

  &__inner-socials-link {
    display: flex;
    font-size: 17px;
    svg {
      margin-right: 5px;
    }
    &:hover span {
      color: goldenrod;
    }
  }

  &__inner-contacts-title {
    color: wheat;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__inner-contacts-text {
    margin-bottom: 5px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    font-size: 17px;
    margin-bottom: 4px;
  }
  &__inner-socials {
    margin-right: 5px;
  }
  &__inner-items {
    margin-right: 5px;
  }
}
