.services {
  font-size: 20px;
  background-color: #1f232b;
  height: 100vh;
  padding-top: 75px;

  color: rgb(66, 63, 97);
  &__title {
    font-size: 3em;
    text-align: center;
    margin-bottom: 60px;
    color: darkturquoise;
  }
  &__items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    color: rgb(66, 63, 97);
  }
  &__item {
    max-width: 350px;
    width: 100%;
    display: block;
    padding: 15px 10px;
    text-align: center;
    background-color: #fff;
    border-radius: 15px;
    transition: 0.3s;
    &:hover {
      box-shadow: 0 0 5px 5px #dbcdcd;
    }
  }
  &__item-title {
    font-size: 28px;
  }
  &__item-suptitle {
    font-size: 20px;
    max-width: 250px;
    margin: 10px auto;
  }
  &__item-text {
    font-size: 16px;
    margin: 5px 0;
  }
  &__guarantee {
    display: flex;
  }
  &__guarantee-item {
    width: calc(100% / 4 - 60px);
    position: relative;
    height: 270px;
    margin: 0 30px;
    padding: 5px;
    text-align: center;
    transition: 0.3s;
    position: relative;
    border: 0 solid gold;
    border-radius: 20px;
    background-color: #fff;
    &:nth-child(1)::after {
      position: absolute;
      content: '';
      top: 20%;
      right: -26%;
      width: 60px;
      height: 5px;
      background-color: aqua;
      z-index: 20;
    }
    &:nth-child(2) {
      &::after {
        position: absolute;
        content: '';
        top: 20%;
        right: -26%;
        width: 60px;
        height: 5px;
        background-color: aqua;
        z-index: 20;
      }
    }
    &:nth-child(3) {
      &::after {
        position: absolute;
        content: '';
        top: 20%;
        right: -26%;
        width: 60px;
        height: 5px;
        background-color: aqua;
        z-index: 20;
      }
    }

    &:hover {
      box-shadow: 0 0 5px 5px #dbcdcd;
    }
  }
  &__guarantee-title {
    margin: 10px 0;
    font-weight: 700;
  }
  &__guarantee-desc {
    font-size: 18px;
  }
}
