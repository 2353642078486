.about {
  background-color: #f9f9fb;
  padding-top: 40px;
  padding-bottom: 40px;
  &__left {
    margin-right: 45px;

    img {
      max-width: 341px;
    }
  }
  &__title {
    margin-bottom: 25px;
    font-size: 28px;
    font-weight: 700;
    color: #423f61;
    &-hidden {
      margin-bottom: 25px;
      font-size: 28px;
      font-weight: 700;
      color: #423f61;
      display: none;
      text-align: center;
    }
  }
  &__inner {
    display: flex;
  }
  &__items {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__item {
    display: flex;
    max-width: 330px;
    margin-bottom: 20px;
  }
  &__img-box img {
    margin-right: 40px;
    width: 32px;
    height: 32px;
  }
  &__info {
    font-size: 18px;
  }
  &__info-title {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}
