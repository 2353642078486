.price {
  padding: 40px 0;
  margin: 25px 0;
  background-color: #fff;
  &__title {
    font-size: 34px;
    font-weight: 700;
    color: #423f61;
    margin-bottom: 30px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #e5e5e5;
    &:first-child .price__price {
      transform: translateX(-7px);
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &__name {
    width: calc(100% * 1 / 3.001);
    span {
      color: #00ace9;
    }
  }
  &__price {
    width: calc(100% * 1 / 3.001);
    text-align: center;
  }
  &__link {
    svg {
      transition: 0.4s;
      margin-left: 10px;
    }
    &:hover svg {
      transform: translateX(7px);
    }
  }
  &__link-box {
    width: calc(100% * 1 / 3.001);
    display: flex;
    justify-content: end;
    transition: 0.2s;

    &:hover {
      color: #00ace9;
      svg {
        fill: #00ace9;
      }
    }
  }
}
