.portfolio {
  margin-top: 30px;
  padding: 10px 0 65px;
  background-color: #f9f9fb;
  &__main-title {
    text-align: center;
    font-size: 34px;
    color: #423f61;
    margin-bottom: 25px;
  }
  &__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
  }
  &__item {
    max-width: 378px;
    width: 100%;
    height: 330px;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 20px;
    &:hover {
      box-shadow: 0 0 5px 5px #ddd;
    }
    &:hover .portfolio__title {
      transform: translateX(10px);
      color: #5d9cec;
    }
    &:hover .portfolio__back-box {
      display: block;
    }
  }
  &__link {
    display: block;
    max-width: 380px;
    width: 100%;
    height: 330px;
  }
  &__img {
    position: relative;
    img {
      border-radius: 10px;
    }
  }
  &__title {
    font-size: 22px;
    font-weight: 700;
    margin: 10px 5px;
    color: #423f61;
    transition: 0.3s;
  }
  &__back-box {
    display: none;
  }
  &__back {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    svg {
      margin-left: 10px;
    }
  }
  &__desc {
    padding-left: 5px;
  }

  ul {
    margin: 0 auto;
    position: absolute;
    bottom: -45px;
    left: 50%;
    transform: translateX(-50%);
  }

  li {
    display: inline-block;
    &.selected {
      a {
        background-color: #4a89dc;
        color: #fff;
      }
    }
    a {
      text-align: center;
      width: 45px;
      line-height: 42px;
      height: 45px;
      border: 1px solid #4a89dc;
      border-radius: 30px;
      margin-right: 10px;
      cursor: pointer;
      display: inline-block;
      color: #4a89dc;

      &:hover {
        background-color: #4a89dc;
        color: #fff;
      }
    }
  }
}
